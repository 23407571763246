<template>
  <div>
    <!-- <div>
      <h2>Search and add a pin</h2>
      <GmapAutocomplete @place_changed="setPlace" />
      <button @click="addMarker">Add</button>
    </div> -->
    <GmapMap
      :center="center"
      :zoom="12"
      class="w-full fixed top-0 h-screen"
      style="height: calc(100vh - 144px)"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {
    return {
      center: { lat: 13.424, lng: 52.539 },
      currentPlace: null,
      markers: [],
      places: []
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker(lat, lng) {
      const position = { lat, lng };
      this.markers.push({ position });
      // if (this.currentPlace) {
      //   const marker = {
      //     lat: this.currentPlace.geometry.location.lat(),
      //     lng: this.currentPlace.geometry.location.lng()
      //   };
      //   this.markers.push({ position: marker });
      //   this.places.push(this.currentPlace);
      //   this.center = marker;
      //   this.currentPlace = null;
      // }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.addMarker(this.center.lat, this.center.lng);
      });
    }
  }
};
</script>
